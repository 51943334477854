var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ObiText',_vm._g(_vm._b({staticClass:"obi-table table",class:[
      _vm.hover && 'table-hover',
      _vm.color && `table-${_vm.color}`,
      _vm.striped && 'table-striped',
      _vm.bordered && 'table-bordered',
      _vm.borderless && 'table-borderless',
   ],attrs:{"as":"table"}},'ObiText',_vm.attrs,false),_vm.$listeners),[(!_vm.hideHeader)?_c('ObiText',{attrs:{"as":"thead"}},[_c('ObiText',{attrs:{"as":"tr"}},_vm._l((_vm.headers),function(col,index){return _c('ObiText',_vm._b({key:index,attrs:{"as":"th"},domProps:{"innerHTML":_vm._s(col.text)}},'ObiText',_vm.getColumnAttributes(col),false))}),1)],1):_vm._e(),_c('ObiText',{attrs:{"as":"tbody"}},[_vm._l((_vm.data),function(row,index){return _c('ObiText',{key:index,attrs:{"as":"tr"},on:{"click":function($event){return _vm.$emit('rowClick', row)},"dblclick":function($event){return _vm.$emit('rowDblClick', row)}}},_vm._l((_vm.headers),function(col,index){return _c('ObiText',_vm._b({key:index,class:{ 'p-0': _vm.noRowPadding },attrs:{"as":"td"}},'ObiText',_vm.getCellAttributes(col),false),[(!_vm.isDataArray)?_vm._t(col.value,function(){return [(row.get(col.value) || row[col.value])?_c('ObiText',{domProps:{"innerHTML":_vm._s(row.get(col.value) || row[col.value])}}):_vm._e()]},{"prop":row.get(col.value) || row[col.value],"item":row}):_vm._t(col.value,function(){return [(row[col.value])?_c('ObiText',{domProps:{"innerHTML":_vm._s(row[col.value])}}):_vm._e()]},{"prop":row[col.value],"item":row})],2)}),1)}),(_vm.isLoading)?_c('vue-loading',{attrs:{"type":"bubbles","color":"#2f71f2","size":{ width: '50px', height: '50px' }}}):_vm._e(),(_vm.lazyLoading)?_c('div',{ref:"lazyLoadingTrigger",staticStyle:{"height":"20px"}}):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }