<template>
   <ObiText
      as="table"
      v-bind="attrs"
      v-on="$listeners"
      class="obi-table table"
      :class="[
         hover && 'table-hover',
         color && `table-${color}`,
         striped && 'table-striped',
         bordered && 'table-bordered',
         borderless && 'table-borderless',
      ]"
   >
      <ObiText as="thead" v-if="!hideHeader">
         <ObiText as="tr">
            <ObiText
               as="th"
               :key="index"
               v-html="col.text"
               v-for="(col, index) in headers"
               v-bind="getColumnAttributes(col)"
            />
         </ObiText>
      </ObiText>
      <ObiText as="tbody">
         <ObiText
            as="tr"
            v-for="(row, index) in data"
            :key="index"
            @click="$emit('rowClick', row)"
            @dblclick="$emit('rowDblClick', row)"
         >
            <ObiText
               as="td"
               v-for="(col, index) in headers"
               :key="index"
               v-bind="getCellAttributes(col)"
               :class="{ 'p-0': noRowPadding }"
            >
               <slot
                  v-if="!isDataArray"
                  :name="col.value"
                  v-bind:prop="row.get(col.value) || row[col.value]"
                  v-bind:item="row"
               >
                  <ObiText v-html="row.get(col.value) || row[col.value]" v-if="row.get(col.value) || row[col.value]" />
               </slot>
               <slot v-else :name="col.value" v-bind:prop="row[col.value]" v-bind:item="row">
                  <ObiText v-html="row[col.value]" v-if="row[col.value]" />
               </slot>
            </ObiText>
         </ObiText>
         <vue-loading
            v-if="isLoading"
            type="bubbles"
            color="#2f71f2"
            :size="{ width: '50px', height: '50px' }"
         ></vue-loading>
         <div v-if="lazyLoading" ref="lazyLoadingTrigger" style="height: 20px;" />
      </ObiText>
   </ObiText>
</template>

<script>
import { keys, omit } from 'lodash';
import { VueLoading } from 'vue-loading-template';

export default {
   name: 'ObiTable',
   components: {
      VueLoading,
   },
   props: {
      options: {
         type: Object,
         default: () => {},
      },
      headers: {
         type: Array,
         default: () => [],
      },
      data: {
         type: Array,
         default: () => [],
      },
      isDataArray: {
         type: Boolean,
         default: false,
      },
      hover: {
         type: Boolean,
         default: false,
      },
      striped: {
         type: Boolean,
         default: true,
      },
      bordered: {
         type: Boolean,
         default: false,
      },
      borderless: {
         type: Boolean,
         default: true,
      },
      color: {
         type: String,
         default: null,
      },
      isLoading: {
         type: Boolean,
         default: false,
      },
      hideHeader: {
         type: Boolean,
         default: false,
      },
      lazyLoading: {
         type: Boolean,
         default: false,
      },
      noRowPadding: {
         type: Boolean,
         default: false,
      },
   },
   created() {
      if (this.$props.lazyLoading) window.addEventListener('scroll', this.handleScroll);
   },
   destroyed() {
      if (this.$props.lazyLoading) window.removeEventListener('scroll', this.handleScroll);
   },
   computed: {
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
   },
   methods: {
      getColumnAttributes(col) {
         return omit(col, ['text', 'value', 'cell']);
      },
      getCellAttributes(col) {
         return col.cell || this.getColumnAttributes(col);
      },
      handleScroll() {
         const lazyLoadingTrigger = this.$refs.lazyLoadingTrigger;
         const triggerPosition = lazyLoadingTrigger.getBoundingClientRect().top;
         const windowHeight = window.innerHeight;

         if (triggerPosition <= windowHeight && !this.$props.isLoading) {
            this.$emit('getNextPage');
         }
      },
   },
};
</script>
<style lang="scss" scoped>
.obi-table {
   margin-bottom: 0;
   thead th {
      position: sticky;
      top: 0;
      z-index: 1;
   }
}
</style>
